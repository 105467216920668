/* eslint-disable i18next/no-literal-string */
import { ExportOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import { RecipeResponse } from '../api/generated';

export const AddRecipe = (props: {
  recipe: RecipeResponse,
  setRecipesToAdd: React.Dispatch<React.SetStateAction<RecipeResponse[]>>,
}) => {
  const recipe = props.recipe;
  const [favourited, setFavourited] = useState(false);

  const handleSubmit = (recipe: RecipeResponse) => {
    setFavourited(!favourited);
    if (favourited) {
      props.setRecipesToAdd(oldRecipes => oldRecipes.filter(r => r != recipe));
    } else {
      props.setRecipesToAdd(oldRecipes => [...oldRecipes, recipe]);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '5px',
        paddingLeft: '5px',
        paddingRight: '10px',
        paddingBottom: '10px',
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        flexWrap: 'wrap',
        marginRight: 10,
        display: 'inline-flex',
        width: '300px',
      }}
    >
      <Card style={{ maxWidth: '300px', width: '300px' }}>
        <CardHeader
          titleTypographyProps={{
            variant: 'subtitle1',
            noWrap: true,
            style: { maxWidth: '250px', width: '250px' },
          }}
          title={recipe.label}
        />
        <CardMedia
          style={{ height: '0', paddingTop: '56.25%' }}
          image={recipe.image_url}
          title={recipe.label}
        />
        <CardActions disableSpacing style={{ justifyContent: 'start' }}>
          <Tooltip title={!favourited ? 'Add to Favourites' : 'Remove from Favourites'}>
            <span>
              <IconButton
                onClick={() => handleSubmit(recipe)}
              >
                {!favourited && (
                  <HeartOutlined height={30} width={30} translate={undefined} rev={undefined} />
                )}
                {favourited && (
                  <HeartFilled
                    height={30}
                    width={30}
                    style={{ color: 'red' }}
                    translate={undefined}
                    rev={undefined}
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Open recipe source">
            <IconButton
              component="a"
              href={recipe.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExportOutlined translate={undefined} rev={undefined} />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    </div>
  );
};
